import * as React from "react"

import Layout from "../components/layout"
import Seo from "../components/seo"

import under_construction from "../images/under_construction.svg"

const ProjektePage = () => (
  <Layout>
    <Seo title="Impressum" />
    <div className="max-w-7xl mx-auto px-4 xl:px-0 xl:py-12">
      <h1 className="text-3xl font-extrabold text-gray-900 mb-4">Impressum</h1>
      <h2 className="text-2xl font-bold">Kontaktadresse</h2>
      <address className="text-left font-normal">
        Adoser GmbH
        <br />
        Schützenstrasse 38o
        <br />
        9100 Herisau
        <br />
        Schweiz
      </address>
      <h2 className="text-2xl font-bold mt-4">Büro & Produktion</h2>
      <address className="text-left font-normal">
        Adoser GmbH
        <br />
        Emmenweidstrasse 64
        <br />
        6020 Emmenbrücke
        <br />
        Schweiz
      </address>
      <h2 className="text-2xl font-bold mt-4">Handelsregistereintrag</h2>

      <p>Eingetragener Firmenname: Adoser GmbH</p>
      <p>Nummer: CHE-113.962.676</p>
      <p>Mehrwertsteuernummer: CHE-113.962.676 MWST</p>
      <br />
      <p>Gerichtstand: Emmenbrücke</p>
      <p>Verantwortlich für den Inhalt Adoser GmbH</p>
      <p>Stand 01. September 2021</p>
    </div>
  </Layout>
)

export default ProjektePage
